.circular-chart {
    display: inline-block;
    margin: 10px;
    text-align: center;
  }
  
  .circular-chart-svg {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 150px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1.5s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .label {
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  
  .circular-chart {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }