.responsive-gif {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* 16:9 aspect ratio */
    overflow: hidden;
  }
  
  .responsive-gif img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }